import type { I18NServiceV1 } from '@oneaudi/i18n-service';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import type { Logger } from '@feature-hub/core';
import type { Translations } from '../i18n';
import { fetchTranslations } from '../i18n';

export type UniversalState = {
  readonly translations: Translations;
};

export async function createUniversalState(
  localeService: GfaLocaleServiceV1,
  i18nService: I18NServiceV1,
  logger: Logger,
): Promise<UniversalState> {
  try {
    const translations = await fetchTranslations(localeService, i18nService);
    return { translations };
  } catch (error) {
    logger.warn('Error fetching translations:', error);
    throw error;
  }
}
