import React from 'react';
import type { TrackingDataV2 } from '@oneaudi/audi-tracking-service';
import { checkLinkTarget, completeTargetURL, getTrackingSearchParam } from '../utils/trackingUtils';
import { useFeatureAppEnv } from '../context/FeatureAppEnvContext';

export interface CtaTexts {
  primary: string;
  secondary: string;
}
export interface Filter {
  id: string;
  values?: string[];
}

export interface TrackingManager {
  ready: (results: number) => void;
  click: (
    targetURL: string,
    label: string,
    linkValue: string,
    carlineGroup: string,
    carline: string,
    amount?: number,
  ) => void;
  trackFilterClick: (
    label: string,
    value: string,
    numberOfCars: number,
    clickedFilter: Filter[],
    relatedFilters: Filter[],
    isAddingFilter: boolean,
    elementName: string,
  ) => void;
  trackResetFilter: (label: string, relatedFilters: Filter[]) => void;
  trackShowMoreFilterClick: (label: string) => void;
  trackShowActionsMenuClick: (value: string, label: string) => void;
  clickVtpLink: (label: string, linktext: string, url: string) => void;
  clickCardImage: (group: string, carline: string) => void;
}

export interface TrackingEvent {
  event: {
    eventInfo: {
      eventAction: string;
      eventName: string;
    };
    attributes: Record<string, unknown>;
  };
  componentUpdate: {
    componentInfo: {
      implementer: number;
      version: string;
    };
  };
}

const createTrackingPayload = (
  action: string,
  name: string,
  attributes: Record<string, unknown>,
): TrackingDataV2 => {
  const defaultAttributes = {
    componentName: 'model-selector',
  };

  return {
    event: {
      eventInfo: {
        eventAction: action,
        eventName: name,
      },
      attributes: {
        ...defaultAttributes,
        ...attributes,
      },
    },
  };
};

export const useTrackingManager = (): TrackingManager => {
  const { 's2:logger': logger, 'audi-tracking-service': trackingService } =
    useFeatureAppEnv().featureServices;

  return React.useMemo(() => {
    const track = (...args: Parameters<typeof createTrackingPayload>): void => {
      if (trackingService) {
        const payload = createTrackingPayload(...args);
        logger.debug('Sending tracking event: ', JSON.stringify(payload, null, 2));
        trackingService.track(payload);
      }
    };

    const trackShowMoreFilterClick = (label: string): void => {
      track('navigation', 'model selector - click to show more filters', {
        label: '...',
        targetURL: '',
        clickID: '',
        elementName: 'tag',
        value: label,
        pos: '',
      });
    };

    const trackShowActionsMenuClick = (label: string, value: string): void => {
      track('navigation', 'model selector - click to open actions menu', {
        label: label.replace(/\s*\(\d+\)/, ''),
        targetURL: '',
        clickID: '',
        elementName: 'text link',
        value,
        pos: '',
      });
    };

    const trackFilterClick = (
      label: string,
      value: string,
      numberOfCars: number,
      clickedFilter: Filter[],
      relatedFilters: Filter[],
      isAddingFilter: boolean,
      elementName: string,
    ): void => {
      const eventAttributes = {
        componentName: 'model-selector',
        label,
        targetURL: 'model selector results',
        clickID: '',
        elementName,
        value,
        pos: numberOfCars.toString(),
        relatedFilter: clickedFilter,
      };

      const componentUpdate = {
        componentInfo: {
          implementer: 2,
          version: __FEATURE_APP_VERSION__,
        },
        attributes: {
          search: {
            name: 'model selector',
            term: '',
            results: numberOfCars.toString(),
            relatedFilters,
          },
        },
      };

      const trackingPayload = {
        event: {
          eventInfo: {
            eventAction: 'filter',
            eventName: `model selector - ${isAddingFilter ? 'add' : 'remove'} filter`,
          },
          attributes: eventAttributes,
        },
        datalayerAdditions: componentUpdate, // Properly separate component update
      };
      logger.debug('Sending tracking event: ', JSON.stringify(trackingPayload, null, 2));

      if (trackingService) {
        trackingService.track(trackingPayload);
      }
    };
    const trackResetFilter = (label: string, relatedFilter: Filter[]) => {
      const deletedFilters = relatedFilter.filter(({ id, values }) => {
        if (id === 'vehicleType' && values?.includes('ALL')) {
          return false;
        }
        return values && values.length > 0;
      });
      track('filter', 'model selector - remove all filters', {
        label,
        targetURL: 'model selector results',
        clickID: '',
        elementName: 'text link',
        value: `reset filter`,
        pos: '',
        deletedFilters,
      });
    };

    const ready = (results: number) => {
      const filter = getTrackingSearchParam();
      const trackingPayload = {
        componentUpdate: {
          componentInfo: {
            componentID: 'model selector',
          },
          attributes: {
            implementer: 2,
            version: __FEATURE_APP_VERSION__,
            search: {
              name: 'model selector',
              term: '',
              results,
              filter,
            },
          },
        },
        event: {
          eventInfo: {
            eventAction: 'feature_app_ready',
            eventName: 'model selector - page',
          },
          attributes: {
            componentName: 'model-selector',
            label: '',
            targetURL: '',
            clickID: '',
            elementName: '',
            value: '',
            pos: '',
          },
        },
      };
      if (trackingService) {
        logger.debug('Sending tracking event: ', JSON.stringify(trackingPayload, null, 2));
        trackingService.track(trackingPayload);
      }
    };

    const click = (
      targetURL: string,
      label: string,
      linkValue: string,
      carlineGroup: string,
      carline: string,
      amount?: number,
    ) => {
      track(checkLinkTarget(targetURL), `model selector - click on ${linkValue}`, {
        label: label.replace(/\s*\(\d+\)/, ''),
        targetURL: completeTargetURL(targetURL),
        elementName: 'text link',
        value: `${carlineGroup}~${carline}`,
        pos: amount ? amount.toString() : '',
      });
    };
    const clickVtpLink = (label: string, linkText: string, url: string) => {
      track(checkLinkTarget(url), label, {
        label: linkText,
        targetURL: url,
        elementName: 'text link',
        clickID: '',
      });
    };
    const clickCardImage = (group: string, carline: string) => {
      track('navigation', 'model selector - click on image', {
        label: '',
        targetURL: '',
        clickID: '',
        elementName: 'image',
        value: `${group}~${carline}`,
        pos: '',
      });
    };

    return {
      ready,
      click,
      trackShowMoreFilterClick,
      trackShowActionsMenuClick,
      trackFilterClick,
      trackResetFilter,
      clickVtpLink,
      clickCardImage,
    };
  }, [trackingService]);
};
