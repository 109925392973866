import React from 'react';
import styled from 'styled-components';
import { Button } from '@audi/audi-ui-react';
import { useContent } from '../context/ContentContext';
import { useUniversalState } from '../context/UniversalStateContext';
import { useTrackingManager } from '../hooks/useTrackingManager';

export const VtpLinks = () => {
  const { stockCarsLink, preownedCarsLink } = useContent();
  const {
    translations: { filterNewcarsLink, filterUsedcarsLink },
  } = useUniversalState();
  const { clickVtpLink } = useTrackingManager();

  const handleClick = (type: string, linkText: string, url: string) => {
    const label = `model selector - click on '${type}' in left navigation`;
    clickVtpLink(label, linkText, url);
  };

  return (
    <VtpLinksWrapper>
      {stockCarsLink && (
        <VtpLink
          variant="text"
          href={stockCarsLink}
          onClick={() => handleClick('new cars', filterNewcarsLink, stockCarsLink)}
        >
          {filterNewcarsLink}
        </VtpLink>
      )}
      {preownedCarsLink && (
        <VtpLink
          variant="text"
          href={preownedCarsLink}
          onClick={() => handleClick('used cars', filterUsedcarsLink, preownedCarsLink)}
        >
          {filterUsedcarsLink}
        </VtpLink>
      )}
    </VtpLinksWrapper>
  );
};

const VtpLinksWrapper = styled.div`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    display: grid;
    gap: var(${(props) => props.theme.responsive.spacing.xs});
    margin-top: calc(
      var(${(props) => props.theme.responsive.spacing.l}) +
        var(${(props) => props.theme.responsive.spacing.s})
    );
    padding-top: calc(
      var(${(props) => props.theme.responsive.spacing.l}) +
        var(${(props) => props.theme.responsive.spacing.s})
    );
    border-top: solid 1px var(${(props) => props.theme.colors.base.grey['15']});
  }
`;

const VtpLink = styled(Button)`
  &:first-of-type {
    padding-bottom: var(${(props) => props.theme.responsive.spacing.m});
  }
`;
