import type { FC } from 'react';
import styled from 'styled-components';
import React from 'react';
import { FrostyBadge } from './FrostyBadge';
import { FuelType } from '../../graphql/types';
import { useUniversalState } from '../context/UniversalStateContext';

interface BadgesProps {
  fuelTypes: FuelType[];
  markAsNew: boolean;
}

export const Badges: FC<BadgesProps> = ({ fuelTypes, markAsNew }) => {
  const badges: { id: string; text: string; type?: 'default' | 'markAsNew' }[] = []; // Strict typing
  const { translations } = useUniversalState();

  if (markAsNew) {
    badges.push({ id: 'newCarline', text: translations.markAsNew, type: 'markAsNew' });
  }
  if (fuelTypes.includes(FuelType.Electrical) && fuelTypes.length === 1) {
    badges.push({ id: 'electrical', text: translations['fuelType.electrical'] });
  } else if (
    fuelTypes.includes(FuelType.Hybrid) ||
    (fuelTypes.includes(FuelType.Electrical) && fuelTypes.includes(FuelType.Petrol))
  ) {
    badges.push({ id: 'hybrid', text: translations['fuelType.hybrid'] });
  }
  if (badges.length === 0) {
    return null;
  }

  return (
    <BadgesWrapper>
      {badges.map(({ id, text, type }) => (
        <FrostyBadge key={id} text={text} type={type} />
      ))}
    </BadgesWrapper>
  );
};

const BadgesWrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: var(${(props) => props.theme.responsive.spacing.s});
  left: var(${(props) => props.theme.responsive.spacing.s});
  display: flex;
  gap: var(${(props) => props.theme.responsive.spacing.xxs});
`;
