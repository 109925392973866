import type { FC, ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';
import { BREAKPOINT_SXXL } from '../constants';

export interface CardListProps {
  readonly children?: ReactNode;
}

export const CardList: FC<CardListProps> = ({ children }) => (
  <CardListWrapper>
    {React.Children.map(children, (child) => (
      <CardListItem>{child}</CardListItem>
    ))}
  </CardListWrapper>
);

const CardListWrapper = styled.div`
  gap: var(${(props) => props.theme.responsive.spacing.l});
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: var(${(props) => props.theme.responsive.spacing.xxl});

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${BREAKPOINT_SXXL}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const CardListItem = styled.div``;
