import type { FC } from 'react';
import React from 'react';
import { Text } from '@audi/audi-ui-react';
import styled from 'styled-components';

interface FrostyBadgeProps {
  readonly text: string;
  readonly type?: 'default' | 'markAsNew';
}

export const FrostyBadge: FC<FrostyBadgeProps> = ({ text, type = 'default' }) => (
  <FrostyBadgeWrapper type={type} data-testid={`badge-${type}`}>
    <FrostyBadgeText variant="copy2">{text}</FrostyBadgeText>
  </FrostyBadgeWrapper>
);

const FrostyBadgeWrapper = styled.div<{ type: 'default' | 'markAsNew' }>`
  padding: 5px 12px;
  background: ${(props) =>
    props.type === 'markAsNew'
      ? `var(${props.theme.colors.ui.highlight.primary})`
      : 'rgb(0 0 0 / 30%)'};
  backdrop-filter: ${(props) => (props.type !== 'markAsNew' ? 'blur(5px)' : 'none')};
`;

const FrostyBadgeText = styled(Text)`
  color: var(${(props) => props.theme.colors.base.brand.white});
`;
