import { Button, Text } from '@audi/audi-ui-react';
import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import type { CarlineAvailableStock } from '../../graphql/types';
import { useI18n } from '../i18n';
import { useFeatureAppEnv } from '../context/FeatureAppEnvContext';
import { useContent } from '../context/ContentContext';
import { useFlyout } from '../context/ActionFlyoutContext';
import withTracking from '../hoc/withTracking';
import type { TrackingManager } from '../hooks/useTrackingManager';

interface ActionsProps {
  readonly id: string;
  readonly title: string;
  readonly group: string;
  readonly discoverLink?: string;
  readonly configureLink?: string;
  readonly availableStock?: CarlineAvailableStock;
  readonly trackingManager: TrackingManager;
}

const Actions: FC<ActionsProps> = ({
  id,
  title,
  group,
  discoverLink,
  configureLink,
  availableStock,
  trackingManager,
}: ActionsProps) => {
  const { actionsFlyoutIsVisible, setActionsFlyoutIsVisible } = useFlyout();
  const { 'audi-number-formatter-service': numberFormatterService } =
    useFeatureAppEnv().featureServices;

  const availableNewCarsText =
    availableStock?.newCars &&
    useI18n('availableNewCars', {
      value: numberFormatterService.formatNumber(availableStock.newCars.amount),
    });

  const availableUsedCarsText =
    availableStock?.usedCars &&
    useI18n('availableUsedCars', {
      value: numberFormatterService.formatNumber(availableStock.usedCars.amount),
    });

  const {
    ctaDisplayOptions: { showConfigureCTA, showNewAndUsedCarsCTA, discoverLinkIsPrimaryCta },
  } = useContent();

  const stockOrUsedCarsAvailable =
    availableStock !== undefined &&
    ((availableStock.newCars?.amount !== undefined && availableStock.newCars.amount > 0) ||
      (availableStock.usedCars?.amount !== undefined && availableStock.usedCars.amount > 0));

  const newAndUsedCarsTotalNumber =
    (availableStock?.newCars?.amount ?? 0) + (availableStock?.usedCars?.amount ?? 0);

  const discoverCTALabel = useI18n('discoverCTALabel');
  const configureCTALabel = useI18n('configureCTALabel');
  const vtpLabel = useI18n('availableNewAndUsedCars', {
    value: numberFormatterService.formatNumber(newAndUsedCarsTotalNumber),
  });
  const handleCtaTrackingButtonClick = (link: string, label: string, linkValue: string) => {
    trackingManager.click(link, label, linkValue, group, title);
  };

  const toggleFlyout = () => {
    if (actionsFlyoutIsVisible === id) {
      setActionsFlyoutIsVisible(null);
    } else {
      setActionsFlyoutIsVisible(id);
      trackingManager.trackShowActionsMenuClick(
        newAndUsedCarsTotalNumber.toString(),
        `${group}~${title}`,
      );
    }
  };

  const createCardAction = () => (
    <ActionWrapper>
      {stockOrUsedCarsAvailable && showNewAndUsedCarsCTA && (
        <Flyout actionsFlyoutIsVisible={actionsFlyoutIsVisible === id}>
          <FlyoutContent>
            <FlyoutHeadline variant="order4" spaceStackStart="m">
              {title}
            </FlyoutHeadline>
            {availableStock.newCars?.amount === 0 ? (
              <StyledText variant="copy2" spaceStackEnd="m">
                {availableNewCarsText}
              </StyledText>
            ) : (
              <Text as="p" variant="copy2" spaceStackEnd="m">
                <a
                  href={availableStock?.newCars?.url}
                  data-testid="newCarsVtpLink"
                  onClick={() =>
                    handleCtaTrackingButtonClick(
                      availableStock.newCars?.url || '',
                      availableNewCarsText || '',
                      'new cars',
                    )
                  }
                >
                  {availableNewCarsText}
                </a>
              </Text>
            )}
            {availableStock.usedCars?.amount === 0 ? (
              <StyledText variant="copy2" spaceStackEnd="m">
                {availableUsedCarsText}
              </StyledText>
            ) : (
              <Text as="p" variant="copy2" spaceStackEnd="m">
                <a
                  href={availableStock?.usedCars?.url}
                  data-testid="usedCarsVtpLink"
                  onClick={() =>
                    handleCtaTrackingButtonClick(
                      availableStock.usedCars?.url || '',
                      availableUsedCarsText || '',
                      'used cars',
                    )
                  }
                >
                  {availableUsedCarsText}
                </a>
              </Text>
            )}
            <FlyoutCloseButton
              variant="icon-tertiary"
              size="small"
              icon="cancel"
              onClick={toggleFlyout}
              aria-label="Close flyout"
            />
          </FlyoutContent>
        </Flyout>
      )}
      <Holder>
        {discoverLinkIsPrimaryCta ? (
          <>
            {discoverLink && (
              <Button
                href={discoverLink}
                variant="primary"
                size="small"
                spaceStackEnd="xxs"
                stretch
                onClick={() =>
                  handleCtaTrackingButtonClick(discoverLink, discoverCTALabel, 'discover')
                }
                aria-label="Discover car"
                data-testid="primaryCta"
              >
                {discoverCTALabel}
              </Button>
            )}
            {!!configureLink && showConfigureCTA && (
              <Button
                href={configureLink}
                variant="secondary"
                size="small"
                spaceStackEnd="xxs"
                stretch
                onClick={() =>
                  handleCtaTrackingButtonClick(configureLink, configureCTALabel, 'configure')
                }
                aria-label="Discover car"
                data-testid="secondaryCta"
              >
                {configureCTALabel}
              </Button>
            )}
          </>
        ) : (
          <>
            {!!configureLink && showConfigureCTA && (
              <Button
                href={configureLink}
                variant="primary"
                size="small"
                spaceStackEnd="xxs"
                stretch
                data-testid="primaryCta"
                onClick={() =>
                  handleCtaTrackingButtonClick(configureLink, configureCTALabel, 'configure')
                }
              >
                {configureCTALabel}
              </Button>
            )}
            {discoverLink && (
              <Button
                href={discoverLink}
                variant={showConfigureCTA ? 'secondary' : 'primary'}
                size="small"
                spaceStackEnd="xxs"
                stretch
                onClick={() =>
                  handleCtaTrackingButtonClick(discoverLink, discoverCTALabel, "'discover'")
                }
                aria-label="Discover car"
                data-testid={showConfigureCTA ? 'secondaryCta' : 'primaryCta'}
              >
                {discoverCTALabel}
              </Button>
            )}
          </>
        )}
        {showNewAndUsedCarsCTA && stockOrUsedCarsAvailable && (
          <FlyoutOpenButton
            icon="car-search"
            variant="text"
            onClick={toggleFlyout}
            size="small"
            aria-label="Open Flyout"
            data-testid="openVtp"
          >
            {vtpLabel}
          </FlyoutOpenButton>
        )}
      </Holder>
    </ActionWrapper>
  );

  return <>{createCardAction()}</>;
};

export default withTracking(Actions);

const ActionWrapper = styled.div`
  /* position: relative; */
`;

const FlyoutHeadline = styled(Text)`
  padding-bottom: var(${(props) => props.theme.responsive.spacing.l});
`;

const FlyoutOpenButton = styled(Button)`
  align-self: center;
  padding-block-start: var(${(props) => props.theme.responsive.spacing.l});
  & span {
    border-bottom: 1px solid var(${(props) => props.theme.colors.interaction.primary.default});
  }
`;
const FlyoutCloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledText = styled(Text)`
  color: var(${(props) => props.theme.colors.interaction.primary.disabled}) !important;
  border-bottom: 1px solid var(${(props) => props.theme.colors.interaction.primary.disabled}) !important;
  font-weight: 700;
`;

const Flyout = styled.div<{ actionsFlyoutIsVisible: boolean }>`
  display: grid;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 20;
  padding: var(${(props) => props.theme.responsive.spacing.l});
  transform: ${(props) => (props.actionsFlyoutIsVisible ? 'translateY(0)' : 'translateY(100%)')};
  transition:
    transform 0.25s ease-in-out,
    opacity 0.25s ease-in-out;
  opacity: ${(props) => (props.actionsFlyoutIsVisible ? 1 : 0)};
  background-color: var(${(props) => props.theme.colors.base.brand.white});
  gap: var(${(props) => props.theme.responsive.spacing.xxs});
  justify-items: center;
`;

const FlyoutContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
  margin-top: var(${(props) => props.theme.responsive.spacing.l});
  background-color: var(${(props) => props.theme.colors.base.brand.white});
`;
