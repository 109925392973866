import { useQuery } from '@oneaudi/onegraph-client';
import type {
  ConfiguredCarByCarlineQuery,
  ConfiguredCarByCarlineQueryVariables,
} from '../../graphql/documents/configured-car-by-carline.generated';
import { ConfiguredCarByCarlineDocument } from '../../graphql/documents/configured-car-by-carline.generated';
import { useFeatureAppEnv } from '../context/FeatureAppEnvContext';

export const getImageWithBackgroundUrl = (
  carlineId: string,
): { loading: boolean; data: string | undefined; error?: string } => {
  const { 'gfa:locale-service': locale } = useFeatureAppEnv().featureServices;

  const { data, loading, error } = useQuery<
    ConfiguredCarByCarlineQuery,
    ConfiguredCarByCarlineQueryVariables
  >(ConfiguredCarByCarlineDocument, {
    variables: {
      carlineInput: {
        brand: 'a',
        carlineId,
        country: locale.countryCode,
        language: locale.language,
      },
      filterInput: {
        filterByProfileId: ['EXTERIOR-FRONT-3-4'],
      },
    },
  });

  let imageUrl: string | undefined;

  if (error) {
    return { loading, data: undefined };
  }

  if (data && data.configuredCarByCarline?.media?.images) {
    imageUrl =
      data.configuredCarByCarline.media?.images?.length > 0
        ? data.configuredCarByCarline.media?.images[0].url
        : undefined;
  }

  return {
    loading,
    data: imageUrl,
  };
};
