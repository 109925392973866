import type { FC } from 'react';
import React from 'react';
import { Button, Text } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { useUniversalState } from '../context/UniversalStateContext';

interface NoResultsProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const NoResults: FC<NoResultsProps> = ({ onClick }) => {
  const { translations } = useUniversalState();

  return (
    <NoResultsWrapper>
      <Headline variant="order1" spaceStackEnd="m">
        {translations.noResultHeadline}
      </Headline>
      <Subheadline variant="order4" spaceStackEnd="l">
        {translations.noResultSubline}
      </Subheadline>
      <Button
        variant="primary"
        size="small"
        onClick={onClick}
        icon="erase"
        aria-label="Reset filter"
      >
        {translations.filterReset}
      </Button>
    </NoResultsWrapper>
  );
};

const NoResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Headline = styled(Text)`
  text-align: center;
`;

const Subheadline = styled(Text)`
  margin-bottom: calc(
    var(${(props) => props.theme.responsive.spacing.l}) +
      var(${(props) => props.theme.responsive.spacing.xs})
  );
`;
