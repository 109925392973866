import type { Logger } from '@feature-hub/core';
import { flattenCarlineGroups } from './filter';
import type { CarlineGroup } from '../../@types/mapped';

export const logMissingBodyTypes = (carlinesGroups: CarlineGroup[], logger: Logger) => {
  const carlinesWithMissingBodyType = flattenCarlineGroups(carlinesGroups).filter(
    (carline) => carline.bodyType?.name === undefined,
  );

  if (carlinesWithMissingBodyType.length > 0) {
    logger.warn(
      `Following carlines don't have a "bodyType" set and therefore are shown in the "more"-section within the initial view of the results. (bodyType eq undefined)`,
      carlinesWithMissingBodyType.reduce(
        (acc: Record<string, string>, carline) => ({
          ...acc,
          [carline.name]: carline.identifier.id,
        }),
        {} as Record<string, string>,
      ),
    );
  }
};
