import React, { useState, useEffect, useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import urlJoin from 'url-join';
import { getOptimizedImageSrc, getSizes, getSrcSet } from '../utils/image';
import { useFeatureAppEnv } from '../context/FeatureAppEnvContext';
import { getImageWithBackgroundUrl } from '../utils/getImageUrl'; // Fetch the image with background

import {
  IMG_SIZE_DESKTOP,
  IMG_SIZE_FULL,
  IMG_SIZE_TABLET,
  IMG_SIZE_SMALL_TABLET,
  IMG_SIZE_IPHONE,
  IMG_SIZE_ZERO,
  CARLINE_JELLYBEAN_IMG_SIZE_DESKTOP,
  CARLINE_JELLYBEAN_IMG_SIZE_FULL,
  CARLINE_JELLYBEAN_IMG_SIZE_TABLET,
  CARLINE_JELLYBEAN_IMG_SIZE_SMALL_TABLET,
  CARLINE_JELLYBEAN_IMG_SIZE_IPHONE,
  CARLINE_JELLYBEAN_IMG_SIZE_ZERO,
  CARLINE_NGC_IMG_SIZE_DESKTOP,
  CARLINE_NGC_IMG_SIZE_FULL,
  CARLINE_NGC_IMG_SIZE_TABLET,
  CARLINE_NGC_IMG_SIZE_SMALL_TABLET,
  CARLINE_NGC_IMG_SIZE_IPHONE,
  CARLINE_NGC_IMG_SIZE_ZERO,
  IMAGE_MIME_TYPE,
  NGC_SCALE_FACTOR,
  JELLYBEAN_SCALE_FACTOR,
} from '../constants';

interface CarImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  index: number;
  carlineId: string;
  fallbackSrc?: string; // JellyBean image in case no ngc image is available
  alt: string;
  backdropSrc?: string;
}

export const CardImage: React.FC<CarImageProps> = ({
  index,
  carlineId,
  fallbackSrc,
  backdropSrc,
  alt,
  ...props
}) => {
  const { baseUrl } = useFeatureAppEnv();
  const [optimizedImageSrc, setOptimizedImageSrc] = useState<string | undefined>(undefined); // Initialize with undefined
  const [imgFailed, setImgFailed] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const { data: ngcImageUrl, loading: imageLoading } = getImageWithBackgroundUrl(carlineId);

  useEffect(() => {
    if (!imageLoading) {
      if (ngcImageUrl) {
        setImageUrl(ngcImageUrl);
        setOptimizedImageSrc(
          getOptimizedImageSrc(ngcImageUrl, {
            extension: IMAGE_MIME_TYPE,
            width: CARLINE_NGC_IMG_SIZE_DESKTOP,
          }),
        );
        setImgFailed(false);
      } else {
        setImageUrl(undefined);
        setOptimizedImageSrc(
          getOptimizedImageSrc(fallbackSrc, {
            extension: IMAGE_MIME_TYPE,
            width: CARLINE_JELLYBEAN_IMG_SIZE_DESKTOP,
          }),
        );
        setImgFailed(true); // to load backdrop image
      }
      setLoading(false);
    }
  }, [imageLoading, ngcImageUrl, fallbackSrc]);

  const imageSizes = useMemo(
    () =>
      imageUrl
        ? [
            CARLINE_NGC_IMG_SIZE_FULL,
            CARLINE_NGC_IMG_SIZE_FULL * 2,
            CARLINE_NGC_IMG_SIZE_DESKTOP,
            CARLINE_NGC_IMG_SIZE_DESKTOP * 2,
            CARLINE_NGC_IMG_SIZE_TABLET,
            CARLINE_NGC_IMG_SIZE_TABLET * 2,
            CARLINE_NGC_IMG_SIZE_SMALL_TABLET,
            CARLINE_NGC_IMG_SIZE_SMALL_TABLET * 2,
            CARLINE_NGC_IMG_SIZE_IPHONE,
            CARLINE_NGC_IMG_SIZE_IPHONE * 2,
            CARLINE_NGC_IMG_SIZE_ZERO,
            CARLINE_NGC_IMG_SIZE_ZERO * 2,
          ]
        : [
            CARLINE_JELLYBEAN_IMG_SIZE_FULL,
            CARLINE_JELLYBEAN_IMG_SIZE_FULL * 2,
            CARLINE_JELLYBEAN_IMG_SIZE_DESKTOP,
            CARLINE_JELLYBEAN_IMG_SIZE_DESKTOP * 2,
            CARLINE_JELLYBEAN_IMG_SIZE_TABLET,
            CARLINE_JELLYBEAN_IMG_SIZE_TABLET * 2,
            CARLINE_JELLYBEAN_IMG_SIZE_SMALL_TABLET,
            CARLINE_JELLYBEAN_IMG_SIZE_SMALL_TABLET * 2,
            CARLINE_JELLYBEAN_IMG_SIZE_IPHONE,
            CARLINE_JELLYBEAN_IMG_SIZE_IPHONE * 2,
            CARLINE_JELLYBEAN_IMG_SIZE_ZERO,
            CARLINE_JELLYBEAN_IMG_SIZE_ZERO * 2,
          ],
    [imageUrl],
  );

  return (
    <>
      {!loading && imgFailed && backdropSrc && (
        <BackdropImage
          src={urlJoin(baseUrl, `${backdropSrc}-${IMG_SIZE_ZERO}.jpg`)}
          alt={alt}
          loading={index === 0 ? 'eager' : 'lazy'}
          srcSet={getSrcSet(
            [
              IMG_SIZE_FULL,
              IMG_SIZE_FULL * 2,
              IMG_SIZE_DESKTOP,
              IMG_SIZE_DESKTOP * 2,
              IMG_SIZE_TABLET,
              IMG_SIZE_TABLET * 2,
              IMG_SIZE_SMALL_TABLET,
              IMG_SIZE_SMALL_TABLET * 2,
              IMG_SIZE_IPHONE,
              IMG_SIZE_IPHONE * 2,
              IMG_SIZE_ZERO,
              IMG_SIZE_ZERO * 2,
            ].map((width) => ({
              src: urlJoin(baseUrl, `${backdropSrc}-${width}.webp`),
              width: `${width}w`,
            })),
          )}
          sizes={getSizes([
            { min: 'sxxl', width: `${IMG_SIZE_FULL}px` },
            { min: 'm', width: `${IMG_SIZE_DESKTOP}px` },
            { min: 's', width: `${IMG_SIZE_TABLET}px` },
            { min: 'xs', width: `${IMG_SIZE_SMALL_TABLET}px` },
            { min: 'xxs', width: `${IMG_SIZE_IPHONE}px` },
            { width: `${IMG_SIZE_ZERO}px` },
          ])}
          width="1422"
          height="1067"
        />
      )}
      {optimizedImageSrc && (
        <Image
          src={optimizedImageSrc}
          alt={alt}
          loading="lazy"
          srcSet={getSrcSet(
            imageSizes.map((width) => {
              const src = getOptimizedImageSrc(optimizedImageSrc, { width });
              const result = { src, width: `${width}w` };
              return result;
            }),
          )}
          sizes={getSizes([
            {
              min: 'sxxl',
              width: `${imageUrl ? CARLINE_NGC_IMG_SIZE_FULL : CARLINE_JELLYBEAN_IMG_SIZE_FULL}px`,
            },
            {
              min: 'm',
              width: `${imageUrl ? CARLINE_NGC_IMG_SIZE_DESKTOP : CARLINE_JELLYBEAN_IMG_SIZE_DESKTOP}px`,
            },
            {
              min: 's',
              width: `${imageUrl ? CARLINE_NGC_IMG_SIZE_TABLET : CARLINE_JELLYBEAN_IMG_SIZE_TABLET}px`,
            },
            {
              min: 'xs',
              width: `${imageUrl ? CARLINE_NGC_IMG_SIZE_SMALL_TABLET : CARLINE_JELLYBEAN_IMG_SIZE_SMALL_TABLET}px`,
            },
            {
              min: 'xxs',
              width: `${imageUrl ? CARLINE_NGC_IMG_SIZE_IPHONE : CARLINE_JELLYBEAN_IMG_SIZE_IPHONE}px`,
            },
            {
              width: `${imageUrl ? CARLINE_NGC_IMG_SIZE_ZERO : CARLINE_JELLYBEAN_IMG_SIZE_ZERO}px`,
            },
          ])}
          width={imageUrl ? '1422' : '1400'}
          height={imageUrl ? '1067' : '601'}
          hasNewImageFormat={!imgFailed}
          {...props}
        />
      )}
    </>
  );
};

const BackdropImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Image = styled.img<{ hasNewImageFormat?: boolean }>`
  transform: ${(props) =>
    props.hasNewImageFormat === true
      ? `scale(${NGC_SCALE_FACTOR}) translateY(-11px)`
      : `scale(${JELLYBEAN_SCALE_FACTOR})`};
  width: 100%;
  object-fit: ${(props) => (props.hasNewImageFormat === true ? 'cover' : 'contain')};
  height: 100%;
  animation: ${fadeInAnimation} 1000ms;
`;
