import React from 'react';
import styled from 'styled-components';

import { Text } from '@audi/audi-ui-react';
import {
  renderTextWithFootnotesReferencesV2,
  cleanupEmissionConsumptionMarkup,
} from '@oneaudi/feature-app-utils';
import { useFeatureAppEnv } from '../context/FeatureAppEnvContext';

interface Consumption {
  consumption?: string | null | undefined;
  emissionCO2?: string | null | undefined;
  co2Class?: string | null | undefined;
  dischargedCO2Class?: string | null | undefined;
  dischargedConsumption?: string | null | undefined;
}

interface ConsumptionAndEmissionProps {
  consumptionData: Consumption;
  carlineName: string;
}

const ConsumptionAndEmission: React.FC<ConsumptionAndEmissionProps> = ({
  consumptionData,
  carlineName,
}) => {
  const logger = useFeatureAppEnv().featureServices['s2:logger'];
  const { consumption, emissionCO2, co2Class, dischargedCO2Class, dischargedConsumption } =
    consumptionData;
  if (!consumption || !emissionCO2) {
    logger.warn('No emission and consumption data for', carlineName);
    return null;
  }

  return (
    <StyledTextWithFootnotes variant="copy2" tint="secondary" data-testid="cae">
      {renderTextWithFootnotesReferencesV2(cleanupEmissionConsumptionMarkup(consumption))}
      {'; '}
      {renderTextWithFootnotesReferencesV2(cleanupEmissionConsumptionMarkup(emissionCO2))}
      {co2Class && (
        <>
          {'; '}
          {renderTextWithFootnotesReferencesV2(cleanupEmissionConsumptionMarkup(co2Class))}
        </>
      )}
      {dischargedConsumption && (
        <>
          {'; '}
          {renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(dischargedConsumption),
          )}
        </>
      )}
      {dischargedCO2Class && (
        <>
          {'; '}
          {renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(dischargedCO2Class),
          )}
        </>
      )}
    </StyledTextWithFootnotes>
  );
};

export const StyledTextWithFootnotes = styled(Text)`
  > sup {
    position: relative;
    top: -1em;
    vertical-align: baseline;
  }
`;

export default ConsumptionAndEmission;
