import type { I18NServiceV1 } from '@oneaudi/i18n-service';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { useUniversalState } from '../context/UniversalStateContext';

export type Translations = Record<string, string>;

const i18nScope = 'fa.audi-feature-app-model-selector';

export async function fetchTranslations(
  localeService: GfaLocaleServiceV1,
  i18nService: I18NServiceV1,
): Promise<Translations> {
  return new Promise((resolve, reject) => {
    i18nService.setScopes([i18nScope]);
    i18nService
      .registerCallback((messages) => {
        const res: Translations = Object.entries(messages[localeService.language]).reduce(
          (acc: Translations, [key, value]) => {
            if (key.includes(i18nScope)) {
              const normalizedKey = key.replace(`${i18nScope}.`, ''); // Remove i18nScope prefix
              acc[normalizedKey] = value;
            }
            return acc;
          },
          {},
        );
        resolve(res);
      })
      .catch(reject);
  });
}

export function useI18n(key: string, variables?: Record<string, string | number>): string {
  const { translations } = useUniversalState();
  let text = translations[key] ?? `__${i18nScope}.${key}__`;

  // If variables are provided, replace placeholders in the text
  if (variables) {
    text = Object.keys(variables)
      .map((variable: string) => {
        const value = variables[variable].toString();
        return text.replace(new RegExp(`\\${'${'}${variable}${'}'}`, 'g'), value);
      })
      .join('');
  }

  return text;
}
