import type { ReactNode } from 'react';
import React, { createContext, useContext, useMemo, useState } from 'react';

interface ActionsFlyoutContextType {
  actionsFlyoutIsVisible: string | null;
  setActionsFlyoutIsVisible: (id: string | null) => void;
}

const FlyoutContext = createContext<ActionsFlyoutContextType | undefined>(undefined);

const FlyoutProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [actionsFlyoutIsVisible, setActionsFlyoutIsVisible] = useState<string | null>(null);
  const value = useMemo<ActionsFlyoutContextType>(
    () => ({ actionsFlyoutIsVisible, setActionsFlyoutIsVisible }),
    [actionsFlyoutIsVisible],
  );

  return <FlyoutContext.Provider value={value}>{children}</FlyoutContext.Provider>;
};

const useFlyout = () => {
  const context = useContext(FlyoutContext);
  if (!context) {
    throw new Error('useFlyout must be used within a FlyoutProvider');
  }
  return context;
};

export { FlyoutProvider, useFlyout };
