import type { FC, ReactNode } from 'react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import type { ContentServiceV1 } from '@oneaudi/content-service';
import { normalizeContent } from '../utils/normalizeContent';

interface PriceDisplayOption {
  showBasePrice: boolean;
  showVat: boolean;
}

interface CaeDisplayOption {
  showCae: boolean;
}

interface CtaDisplayOptions {
  showConfigureCTA: boolean;
  showNewAndUsedCarsCTA: boolean;
  discoverLinkIsPrimaryCta: boolean;
}

export interface Content {
  readonly stockCarsLink: string;
  readonly preownedCarsLink: string;
  readonly priceFootnotes: string[];
  readonly caeDisplayOption: CaeDisplayOption;
  readonly ctaDisplayOptions: CtaDisplayOptions;
  readonly priceDisplayOption: PriceDisplayOption;
  readonly preFilteredCarlineGroup: string;
  readonly disclaimer: string;
}

export interface FalconContent {
  readonly __type: 'aem-headless';
  readonly fields: {
    readonly stockCarsLink: string;
    readonly preownedCarsLink: string;
    readonly priceDisplayOption_showBasePrice: boolean;
    readonly priceDisplayOption_showVat: boolean;
    readonly caeDisplayOption_showCae: boolean;
    readonly ctaDisplayOptions_showConfigureCTA: boolean;
    readonly ctaDisplayOptions_showNewAndUsedCarsCTA: boolean;
    readonly ctaDisplayOptions_discoverLinkIsPrimaryCta: boolean;
    readonly priceFootnotes: string[];
    readonly preFilteredCarlineGroup: string;
    readonly disclaimer: string;
  };
}

const ContentContext = createContext<Content | undefined>(undefined);

export interface ContentContextProviderProps {
  readonly contentService: ContentServiceV1;
  readonly children?: ReactNode;
}

export const ContentContextProvider: FC<ContentContextProviderProps> = ({
  contentService,
  children,
}) => {
  const [content, setContent] = useState<Content>(() => normalizeContent(contentService));

  useEffect(() => {
    const contentChangeHandler = () => {
      setContent(normalizeContent(contentService));
    };

    contentService.onContentChange(contentChangeHandler);

    return () => {
      contentService.removeOnContentChange(contentChangeHandler);
    };
  }, [contentService]);

  return <ContentContext.Provider value={content}>{children}</ContentContext.Provider>;
};

export function useContent(): Content {
  const context = useContext(ContentContext);
  if (!context) {
    throw new Error(
      'The context was not defined. Did you forget to wrap the feature app in <ContentContextProvider>?',
    );
  }
  return context;
}
