import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Text } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { RenderMode } from '@oneaudi/render-mode-service';
import { useFeatureAppEnv } from './context/FeatureAppEnvContext';
import { FilterCategoryButton } from './components/button/FilterCategoryButton';
import type { DisplayedVehicleType } from './utils/filter';
import {
  filterByBodyType,
  filterByCarlineGroup,
  filterCarlineGroupsByVehicleType,
  flattenCarlineGroups,
  getDefaultVehicleType,
  vehicleTypes,
} from './utils/filter';
import { useUniversalState } from './context/UniversalStateContext';
import { TrackedFilterBodyTypeTags } from './components/button/FilterBodyTypeTags';
import { TrackedFilterCarlineTags } from './components/button/FilterCarlineTags';
import { Results } from './components/Results';
import { getBodyTypePreset, getCarlineGroupPreset, getVehicleTypePreset } from './utils/urlPresets';
import { VtpLinks } from './components/VtpLinks';
import { useContent } from './context/ContentContext';
import { fetchCarlineGroups } from './utils/fetchCarlineGroups';
import { logMissingBodyTypes } from './utils/logMissingBodyTypes';
import Disclaimer from './components/Disclaimer';
import type { Filter } from './hooks/useTrackingManager';
import { useTrackingManager } from './hooks/useTrackingManager';

export const FeatureApp: React.FC = () => {
  const { featureServices } = useFeatureAppEnv();
  const { preFilteredCarlineGroup } = useContent();
  // useEffect only needed for demo Face update to work
  useEffect(() => {
    setSelectedCarlineGroups(getCarlineGroupPreset(preFilteredCarlineGroup));
  }, [preFilteredCarlineGroup]);

  const [selectedCarlineGroups, setSelectedCarlineGroups] = useState<string[]>(
    getCarlineGroupPreset(preFilteredCarlineGroup),
  );
  const [selectedBodyTypes, setSelectedBodyTypes] = useState<string[]>(getBodyTypePreset());
  const [selectedVehicleType, setSelectedVehicleType] = useState<DisplayedVehicleType | undefined>(
    getVehicleTypePreset(selectedCarlineGroups.length > 0 || selectedBodyTypes.length > 0),
  );
  const { translations } = useUniversalState();
  const hasActiveFilters = useMemo(
    () => selectedBodyTypes.length + selectedCarlineGroups.length > 0,
    [selectedBodyTypes, selectedCarlineGroups],
  );
  const { 'gfa:locale-service': locale, 'audi-render-mode-service': renderModeService } =
    featureServices;
  const renderMode = renderModeService?.getRenderMode() ?? 0;

  const allCarlineGroups = fetchCarlineGroups(locale.countryCode, locale.language);
  useEffect(() => {
    logMissingBodyTypes(allCarlineGroups, featureServices['s2:logger']);
  }, [allCarlineGroups]);

  const preFilteredCarlineGroups = useMemo(
    () => filterCarlineGroupsByVehicleType(allCarlineGroups, selectedVehicleType),
    [selectedVehicleType, allCarlineGroups],
  );

  const flattenedCarlines = useMemo(
    () => flattenCarlineGroups(preFilteredCarlineGroups),
    [preFilteredCarlineGroups],
  );

  const carlinesFilteredByCarlineGroup = useMemo(
    () => filterByCarlineGroup(flattenedCarlines, selectedCarlineGroups),
    [flattenedCarlines, selectedCarlineGroups],
  );
  const carlinesFilteredByBodyType = useMemo(
    () => filterByBodyType(flattenedCarlines, selectedBodyTypes),
    [flattenedCarlines, selectedBodyTypes],
  );

  const filteredCarlines = filterByBodyType(carlinesFilteredByCarlineGroup, selectedBodyTypes);

  const resetAllFilters = () => {
    setSelectedBodyTypes([]);
    setSelectedCarlineGroups([]);
    setSelectedVehicleType('ALL');
  };

  const createFilterCarlineSection = (cutItemsAfter?: number) => (
    <FilterSection>
      <FilterSectionLabel variant="copy2">{translations.filterModel}</FilterSectionLabel>

      <TrackedFilterCarlineTags
        carlineGroups={
          preFilteredCarlineGroups.length === 0 ? allCarlineGroups : preFilteredCarlineGroups
        }
        filteredCarlines={carlinesFilteredByBodyType}
        selectedCarlineGroups={selectedCarlineGroups}
        createOnClickHandler={(id: string) => () => {
          if (!selectedVehicleType) {
            setSelectedVehicleType(getDefaultVehicleType());
          }

          setSelectedCarlineGroups((previousSelectedCarlineGroups) => {
            const isAlreadySelected = previousSelectedCarlineGroups.includes(id);
            const newSelectedCarlineGroups = isAlreadySelected
              ? previousSelectedCarlineGroups.filter((carlineGroup) => carlineGroup !== id)
              : [...previousSelectedCarlineGroups, id];

            const carlinesAfterBodyFilter = filterByBodyType(flattenedCarlines, selectedBodyTypes);
            const numberOfCars = filterByCarlineGroup(
              carlinesAfterBodyFilter,
              newSelectedCarlineGroups,
            ).length;

            const clickedFilter = [{ id: 'carlineGroup', values: [id] }];
            const relatedFilters: Filter[] = [
              ...(selectedBodyTypes.length > 0
                ? [{ id: 'bodyTypes', values: selectedBodyTypes }]
                : []),
              ...(newSelectedCarlineGroups.length > 0
                ? [{ id: 'carlineGroup', values: newSelectedCarlineGroups }]
                : []),
              ...(selectedVehicleType && selectedVehicleType !== 'ALL'
                ? [{ id: 'vehicleType', values: [selectedVehicleType as string] }]
                : []),
            ];

            trackingManager.trackFilterClick(
              translations[`carlineGroup.${id}`], // label
              id, // value
              numberOfCars, // pos
              clickedFilter,
              relatedFilters, // componentUpdate
              !isAlreadySelected, // add/remove for eventName
              'tag', // elementName
            );
            return newSelectedCarlineGroups;
          });
          scrollToTop(featureAppContainerRef);
        }}
        cutItemsAfter={
          preFilteredCarlineGroup && preFilteredCarlineGroup.length > 0 ? undefined : cutItemsAfter
        }
      />
    </FilterSection>
  );

  const createFilterBodyTypeSection = (cutItemsAfter?: number) => (
    <FilterSection>
      <FilterSectionLabel variant="copy2">{translations.filterBodytype}</FilterSectionLabel>
      <TrackedFilterBodyTypeTags
        flattenedCarlines={
          flattenedCarlines.length === 0
            ? flattenCarlineGroups(allCarlineGroups)
            : flattenedCarlines
        }
        filteredCarlines={carlinesFilteredByCarlineGroup}
        selectedBodyTypes={selectedBodyTypes}
        createOnClickHandler={(id: string) => () => {
          if (!selectedVehicleType) {
            setSelectedVehicleType(getDefaultVehicleType());
          }

          setSelectedBodyTypes((previousSelectedBodyTypes) => {
            const isAlreadySelected = previousSelectedBodyTypes.includes(id);

            const newSelectedBodyTypes = isAlreadySelected
              ? previousSelectedBodyTypes.filter((bodyType) => bodyType !== id)
              : [...previousSelectedBodyTypes, id];

            const carlinesAfterCarlineFilter = filterByCarlineGroup(
              flattenedCarlines,
              selectedCarlineGroups,
            );
            const numberOfCars = filterByBodyType(
              carlinesAfterCarlineFilter,
              newSelectedBodyTypes,
            ).length;

            const clickedFilter = [{ id: 'bodyType', values: [id] }];
            const relatedFilters: Filter[] = [
              ...(newSelectedBodyTypes.length > 0
                ? [{ id: 'bodyTypes', values: newSelectedBodyTypes }]
                : []),
              ...(selectedCarlineGroups.length > 0
                ? [{ id: 'carlineGroup', values: selectedCarlineGroups }]
                : []),
              ...(selectedVehicleType && selectedVehicleType !== 'ALL'
                ? [{ id: 'vehicleType', values: [selectedVehicleType] }]
                : []),
            ];

            trackingManager.trackFilterClick(
              translations[`bodyType.${id}`],
              id,
              numberOfCars,
              clickedFilter,
              relatedFilters,
              !isAlreadySelected,
              'tag',
            );

            return newSelectedBodyTypes;
          });

          scrollToTop(featureAppContainerRef);
        }}
        cutItemsAfter={
          preFilteredCarlineGroup && preFilteredCarlineGroup.length > 0 ? undefined : cutItemsAfter
        }
      />
    </FilterSection>
  );

  const createFilterSectionForCategory = () =>
    vehicleTypes.map((vehicleType: DisplayedVehicleType) => (
      <React.Fragment key={vehicleType as string}>
        {filterCarlineGroupsByVehicleType(allCarlineGroups, vehicleType).length > 0 &&
          selectedVehicleType &&
          vehicleType === selectedVehicleType && (
            <FilterSectionHolder>
              {createFilterCarlineSection(6)}
              {createFilterBodyTypeSection(3)}
              {hasActiveFilters && (
                <FilterResetButton
                  variant="text"
                  size="small"
                  onClick={() => {
                    trackingManager.trackResetFilter(translations.filterReset, [
                      { id: 'bodyType', values: selectedBodyTypes },
                      { id: 'carlineGroup', values: selectedCarlineGroups },
                      { id: 'vehicleType', values: [selectedVehicleType] },
                    ]);
                    setSelectedBodyTypes([]);
                    setSelectedCarlineGroups([]);
                  }}
                  icon="erase"
                  aria-label="Reset filter for vehicle types"
                >
                  {translations.filterReset}
                </FilterResetButton>
              )}
            </FilterSectionHolder>
          )}
      </React.Fragment>
    ));

  const scrollToFilterNode = (target: EventTarget) => {
    if (target instanceof Element) {
      (target as unknown as Element).scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'start',
      });
    }
  };

  const featureAppContainerRef = useRef<HTMLDivElement>(null);
  const scrollToTop = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const categoryButtonRef = useRef<Array<HTMLButtonElement | null>>([]);

  const filterHorizontalUnderlineRef = useRef<HTMLDivElement>(null);

  const moveUnderline = (button: EventTarget | HTMLButtonElement) => {
    if (
      button instanceof Element &&
      filterHorizontalUnderlineRef !== null &&
      filterHorizontalUnderlineRef.current !== null &&
      categoryButtonRef !== null &&
      categoryButtonRef.current[0] !== null
    ) {
      const { width, left } = button.getBoundingClientRect();
      const paddingLeft = categoryButtonRef.current[0].getBoundingClientRect().left;

      filterHorizontalUnderlineRef.current.style.left = `${left - paddingLeft}px`;
      filterHorizontalUnderlineRef.current.style.width = `${width}px`;
    }
  };

  const handleVehicleTypeClick = (vehicleType: DisplayedVehicleType) => {
    if (!selectedVehicleType || vehicleType !== selectedVehicleType) {
      setSelectedBodyTypes([]);
      setSelectedCarlineGroups([]);
      setSelectedVehicleType(vehicleType);

      const carlineGroupsAfterTypeFilter = filterCarlineGroupsByVehicleType(
        allCarlineGroups,
        vehicleType,
      );
      const numberOfCars = flattenCarlineGroups(carlineGroupsAfterTypeFilter).length;

      const clickedFilter = [{ id: 'vehicleType', values: [vehicleType] }];
      const relatedFilters: Filter[] = [];

      trackingManager.trackFilterClick(
        translations[`vehicleType.${vehicleType}`],
        vehicleType,
        numberOfCars,
        clickedFilter,
        relatedFilters,
        true,
        'text link',
      );
    }
  };

  const getActiveCategoryButton = () =>
    categoryButtonRef.current.filter(
      (button) => button!.getAttribute('data-isactive') === 'true',
    )[0]!;

  useEffect(() => {
    moveUnderline(getActiveCategoryButton());
  }, [selectedVehicleType]);

  useEffect(() => {
    moveUnderline(getActiveCategoryButton());
  });

  useEffect(() => {
    function handleResize() {
      moveUnderline(getActiveCategoryButton());
    }
    if (renderMode === RenderMode.DEFAULT) {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
    return undefined;
  }, [renderMode]);
  const trackingManager = useTrackingManager();

  const hasFired = useRef(false);
  useEffect(() => {
    if (!hasFired.current && preFilteredCarlineGroups.length > 0) {
      trackingManager.ready(filteredCarlines.length);
      hasFired.current = true;
    }
  }, [filteredCarlines]);

  return (
    <Layout ref={featureAppContainerRef} data-testid="fa-model-selector">
      <InputWrapper>
        <FilterWrapperMobile data-testid="filter-mobile">
          <FilterHorizontalFader>
            <FilterHorizontalHolder>
              <FilterHorizontalSlider>
                {vehicleTypes.map((vehicleType: DisplayedVehicleType, index) => (
                  <React.Fragment key={vehicleType as string}>
                    {filterCarlineGroupsByVehicleType(allCarlineGroups, vehicleType).length > 0 && (
                      <FilterCategoryButton
                        onClickHandler={(event) => {
                          handleVehicleTypeClick(vehicleType);
                          scrollToFilterNode(event.target);
                        }}
                        text={translations[`vehicleType.${vehicleType.toString()}`]}
                        isActive={selectedVehicleType && vehicleType === selectedVehicleType}
                        // @ts-expect-error works, could be refactored
                        ref={(vehicleTypeForCategoryButton: HTMLButtonElement) => {
                          categoryButtonRef.current[index] = vehicleTypeForCategoryButton;
                          return vehicleTypeForCategoryButton;
                        }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </FilterHorizontalSlider>
              <FilterHorizontalUnderlineWrapper>
                <FilterHorizontalUnderline ref={filterHorizontalUnderlineRef} />
              </FilterHorizontalUnderlineWrapper>
            </FilterHorizontalHolder>
          </FilterHorizontalFader>
          {createFilterSectionForCategory()}
        </FilterWrapperMobile>
        <FilterWrapperDesktop data-testid="filter-desktop">
          {vehicleTypes.map((vehicleType: DisplayedVehicleType) => (
            <React.Fragment key={vehicleType as string}>
              {filterCarlineGroupsByVehicleType(allCarlineGroups, vehicleType).length > 0 && (
                <>
                  <FilterCategoryButton
                    onClickHandler={() => {
                      handleVehicleTypeClick(vehicleType);
                      scrollToTop(featureAppContainerRef);
                    }}
                    text={translations[`vehicleType.${vehicleType.toString()}`]}
                    isActive={selectedVehicleType && vehicleType === selectedVehicleType}
                    aria-label={`Filter category ${vehicleType.toString()}`}
                  />
                  {vehicleType === selectedVehicleType && createFilterSectionForCategory()}
                </>
              )}
            </React.Fragment>
          ))}
        </FilterWrapperDesktop>
        <VtpLinks />
      </InputWrapper>
      <ResultWrapper>
        <Results
          carlineGroups={preFilteredCarlineGroups}
          filteredCarlines={filteredCarlines}
          hasActiveFilters={hasActiveFilters}
          resetAllFilters={resetAllFilters}
          selectedVehicleType={selectedVehicleType}
        />
        <Disclaimer />
      </ResultWrapper>
    </Layout>
  );
};

const Layout = styled.div`
  position: relative;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }
`;

const InputWrapper = styled.div`
  box-sizing: border-box;
  padding: 0 var(${(props) => props.theme.responsive.spacing.pageMargin});

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    position: sticky;
    top: 0;
    min-width: 320px;
    height: calc(100vh);
    padding: var(${(props) => props.theme.responsive.spacing.xxl})
      var(${(props) => props.theme.responsive.spacing.m})
      var(${(props) => props.theme.responsive.spacing.xxl})
      var(${(props) => props.theme.responsive.spacing.pageMargin});
    overflow-y: auto;
    .adobe-ue-edit & {
      // this variable is provided by the UE integration
      // it defines the initial viewport height in px
      // before the UE started resizing the iframe
      max-height: var(--ue-viewport-height);
    }
  }

  @media (min-width: 1280px) {
    min-width: 388px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    min-width: auto;
  }
`;

const ResultWrapper = styled.div`
  padding: var(${(props) => props.theme.responsive.spacing.xxl})
    var(${(props) => props.theme.responsive.spacing.pageMargin});
  background-color: var(${(props) => props.theme.colors.base.grey['5']});
`;

const FilterWrapperMobile = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    display: none;
  }
`;

const FilterWrapperDesktop = styled.div`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    display: block;
  }
`;

const FilterHorizontalFader = styled.div`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: var(${(props) => props.theme.responsive.spacing.l});
    height: 100%;
    background-image: linear-gradient(to right, #fff0, white);
  }
`;

const FilterHorizontalHolder = styled.div`
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const FilterHorizontalUnderlineWrapper = styled.div`
  position: relative;
  height: 1px;
  background-color: var(${(props) => props.theme.colors.ui.divider});
`;

const FilterHorizontalUnderline = styled.div`
  position: absolute;
  height: 1px;
  transition:
    width 500ms ease-in-out,
    left 500ms ease-in-out;
  background-color: var(${(props) => props.theme.colors.interaction.secondary.active});
`;

const FilterHorizontalSlider = styled.div`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  min-width: max-content;
  padding-right: var(${(props) => props.theme.responsive.spacing.xl});
  gap: var(${(props) => props.theme.responsive.spacing.l});

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    gap: var(${(props) => props.theme.responsive.spacing.xxl});
  }

  & > button {
    white-space: nowrap;

    & > svg {
      display: none;
    }
  }
`;

const FilterSectionHolder = styled.div`
  padding: var(${(props) => props.theme.responsive.spacing.l}) 0
    var(${(props) => props.theme.responsive.spacing.xxl}) 0;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    padding: var(${(props) => props.theme.responsive.spacing.l}) 0
      calc(
        var(${(props) => props.theme.responsive.spacing.l}) +
          var(${(props) => props.theme.responsive.spacing.m})
      )
      0;
  }
`;

const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: var(${(props) => props.theme.responsive.spacing.xs});

  & + & {
    margin-top: var(${(props) => props.theme.responsive.spacing.l});
  }
`;

const FilterSectionLabel = styled(Text)`
  margin-bottom: var(${(props) => props.theme.responsive.spacing.xxs});
`;

const FilterResetButton = styled(Button)`
  margin-top: var(${(props) => props.theme.responsive.spacing.l});
`;
