import type { FC } from 'react';
import React from 'react';
import { Text } from '@audi/audi-ui-react';
import { Card } from './Card';
import { CardList } from './CardList';
import type { Carline, DisplayedVehicleType } from '../utils/filter';
import { NoResults } from './NoResults';
import type { CarlineGroup } from '../../@types/mapped';
import { ResultCountHeader } from './ResultCountHeader';

const getCardForCarline = (carline: Carline, index: number) => {
  const {
    identifier: { id },
    name,
    exteriorViews,
    prices: { minPrice },
    links,
    consumptionAndEmission,
    availableStock,
    isFake,
    fuelTypes,
    group,
    markAsNew,
  } = carline;
  const configureUrl = links?.shopTheLookPage?.url ?? links?.configuratorPage?.url;

  return (
    <Card
      index={index}
      key={id}
      title={name}
      carlineId={id}
      transparentImageUrl={exteriorViews?.beautyFront}
      price={!isFake ? minPrice?.value : undefined}
      configureLink={configureUrl || undefined}
      discoverLink={links?.carlineStartPage?.url || undefined}
      consumptionAndEmission={consumptionAndEmission?.summary || undefined}
      availableStock={availableStock || undefined}
      fuelTypes={fuelTypes}
      group={group}
      markAsNew={markAsNew}
    />
  );
};

export interface ResultsProps {
  readonly carlineGroups: CarlineGroup[];
  readonly filteredCarlines: Carline[];
  readonly hasActiveFilters: boolean;
  readonly resetAllFilters: (event: React.MouseEvent<HTMLButtonElement>) => void;
  readonly selectedVehicleType?: DisplayedVehicleType;
}

export const Results: FC<ResultsProps> = ({
  hasActiveFilters,
  carlineGroups,
  filteredCarlines,
  resetAllFilters,
  selectedVehicleType,
}) => {
  const hasCarlineGroups = carlineGroups.length > 0;
  const hasFilteredCarlines = filteredCarlines.length > 0;

  const resultCountHeader =
    hasCarlineGroups && hasFilteredCarlines ? (
      <ResultCountHeader
        hasActiveFilters={hasActiveFilters}
        selectedVehicleType={selectedVehicleType}
        filteredCount={filteredCarlines.length}
      />
    ) : null;

  const noResults =
    hasActiveFilters && hasCarlineGroups && !hasFilteredCarlines ? (
      <NoResults onClick={resetAllFilters} />
    ) : null;

  const filteredCards = hasActiveFilters ? (
    <CardList>
      {filteredCarlines.map((carline, index) => getCardForCarline(carline, index))}
    </CardList>
  ) : null;

  const groupedCards = !hasActiveFilters
    ? carlineGroups.map((group) => (
        <React.Fragment key={group.identifier.id}>
          <Text variant="order4" spaceStackEnd="l">
            {group.name}
          </Text>
          <CardList>
            {group.carlines.map((carline, index) =>
              getCardForCarline({ group: group.identifier.id, ...carline }, index),
            )}
          </CardList>
        </React.Fragment>
      ))
    : null;

  return (
    noResults || (
      <>
        {resultCountHeader}
        {filteredCards}
        {groupedCards}
      </>
    )
  );
};
