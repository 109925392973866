export const BREAKPOINT_SXXL = 1600;
export const BREAKPOINT_IPHONE = 414;
export const BREAKPOINT_SMALL_TABLET = 601;

// backdrop
export const IMG_SIZE_FULL = 400;
export const IMG_SIZE_DESKTOP = 492;
export const IMG_SIZE_TABLET = 460;
export const IMG_SIZE_SMALL_TABLET = 543;
export const IMG_SIZE_IPHONE = 358;
export const IMG_SIZE_ZERO = 319;

export const NGC_SCALE_FACTOR = 1.2;
export const JELLYBEAN_SCALE_FACTOR = 0.95;

// for ngc image
export const CARLINE_NGC_IMG_SIZE_FULL = Math.ceil(IMG_SIZE_FULL * NGC_SCALE_FACTOR);
export const CARLINE_NGC_IMG_SIZE_DESKTOP = Math.ceil(IMG_SIZE_DESKTOP * NGC_SCALE_FACTOR);
export const CARLINE_NGC_IMG_SIZE_TABLET = Math.ceil(IMG_SIZE_TABLET * NGC_SCALE_FACTOR);
export const CARLINE_NGC_IMG_SIZE_SMALL_TABLET = Math.ceil(
  IMG_SIZE_SMALL_TABLET * NGC_SCALE_FACTOR,
);
export const CARLINE_NGC_IMG_SIZE_IPHONE = Math.ceil(IMG_SIZE_IPHONE * NGC_SCALE_FACTOR);
export const CARLINE_NGC_IMG_SIZE_ZERO = Math.ceil(IMG_SIZE_ZERO * NGC_SCALE_FACTOR);

// for jellybean images
export const CARLINE_JELLYBEAN_IMG_SIZE_FULL = Math.ceil(IMG_SIZE_FULL * JELLYBEAN_SCALE_FACTOR);
export const CARLINE_JELLYBEAN_IMG_SIZE_DESKTOP = Math.ceil(
  IMG_SIZE_DESKTOP * JELLYBEAN_SCALE_FACTOR,
);
export const CARLINE_JELLYBEAN_IMG_SIZE_TABLET = Math.ceil(
  IMG_SIZE_TABLET * JELLYBEAN_SCALE_FACTOR,
);
export const CARLINE_JELLYBEAN_IMG_SIZE_SMALL_TABLET = Math.ceil(
  IMG_SIZE_SMALL_TABLET * JELLYBEAN_SCALE_FACTOR,
);
export const CARLINE_JELLYBEAN_IMG_SIZE_IPHONE = Math.ceil(
  IMG_SIZE_IPHONE * JELLYBEAN_SCALE_FACTOR,
);
export const CARLINE_JELLYBEAN_IMG_SIZE_ZERO = Math.ceil(IMG_SIZE_ZERO * JELLYBEAN_SCALE_FACTOR);

export const IMAGE_MIME_TYPE = 'webp';
