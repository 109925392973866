import React from 'react';
import { Text } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { useI18n } from '../i18n';
import type { DisplayedVehicleType } from '../utils/filter';

interface ResultCountHeaderProps {
  hasActiveFilters: boolean;
  selectedVehicleType?: DisplayedVehicleType;
  filteredCount: number;
}

export const ResultCountHeader: React.FC<ResultCountHeaderProps> = ({
  hasActiveFilters,
  selectedVehicleType,
  filteredCount,
}) => {
  let resultCount: string;

  if (hasActiveFilters) {
    resultCount = useI18n('resultCount');
  } else if (selectedVehicleType === 'ALL' || selectedVehicleType === undefined) {
    resultCount = useI18n('vehicleType.ALL');
  } else if (selectedVehicleType === ('BEV' as DisplayedVehicleType)) {
    resultCount = useI18n('vehicleType.BEV');
  } else if (selectedVehicleType === ('PHEV' as DisplayedVehicleType)) {
    resultCount = useI18n('vehicleType.PHEV');
  } else {
    resultCount = useI18n('vehicleType.ALL'); // Fallback
  }

  return (
    <CountWrapper>
      <Text variant="copy2" as="span">
        {resultCount}
      </Text>
      <StyledText variant="copy2">{`(${filteredCount})`}</StyledText>
    </CountWrapper>
  );
};

const CountWrapper = styled.div`
  display: flex;
  align-items: baseline;
  padding-bottom: var(${(props) => props.theme.responsive.spacing.l});
  gap: var(${(props) => props.theme.responsive.spacing.xxs});
`;

const StyledText = styled(Text)`
  color: var(${(props) => props.theme.colors.ui.tertiary});
`;
