import React, { type FC } from 'react';
import styled from 'styled-components';
import { Icon } from '@audi/audi-ui-react';

export interface FilterTagProps {
  readonly onClickHandler: () => void;
  readonly text: string;
  readonly selected?: boolean;
  readonly disabled?: boolean;
  // todo: there is no export for IconNamesMerged, so by now a workaround is used here
  readonly icon?: 'more';
  readonly dataTestId?: string;
}

export const FilterTag: FC<FilterTagProps> = ({
  onClickHandler,
  text,
  selected = false,
  disabled = false,
  icon,
  dataTestId,
}) => (
  <StyledButton
    type="button"
    onClick={!disabled ? onClickHandler : undefined}
    selected={selected}
    disabled={disabled}
    aria-disabled={disabled}
    aria-label={`Filter for ${text}`}
    hasIcon={!!icon}
    data-testid={dataTestId}
  >
    {text}
    {icon && <Icon name={icon} size="xs" />}
  </StyledButton>
);

interface StyledButtonProps {
  readonly selected: boolean;
  readonly disabled: boolean;
  readonly hasIcon: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${(props) =>
    props.hasIcon
      ? `var(${props.theme.responsive.spacing.xs})`
      : `var(${props.theme.responsive.spacing.xs}) var(${props.theme.responsive.spacing.m})`};
  column-gap: var(${(props) => props.theme.responsive.spacing.xxs});
  border-width: 1px;
  border-style: solid;
  border-color: var(${(props) => props.theme.colors.ui.divider});
  background: var(
      ${(props) =>
        props.selected ? props.theme.colors.base.grey['5'] : props.theme.colors.base.brand.white}
    )
    none;

  color: var(
    ${(props) => {
      if (props.selected) {
        return props.theme.colors.ui.primary;
      }
      if (props.disabled) {
        return props.theme.colors.interaction.primary.disabled;
      }
      return props.theme.colors.ui.tertiary;
    }}
  );

  font-family: var(${(props) => props.theme.responsive.typography.copy2.fontFamily});
  font-size: var(${(props) => props.theme.responsive.typography.copy2.fontSize});
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-stretch: var(${(props) => props.theme.responsive.typography.copy2.fontStretch});
  line-height: var(${(props) => props.theme.responsive.spacing.l});
  line-height: var(${(props) => props.theme.responsive.typography.copy2.lineHeight});
  text-align: start;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  aspect-ratio: ${(props) => (props.hasIcon ? '1 / 1' : 'auto')};

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    font-family: var(${(props) => props.theme.responsive.typography.copy1.fontFamily});
    font-size: var(${(props) => props.theme.responsive.typography.copy1.fontSize});
    font-stretch: var(${(props) => props.theme.responsive.typography.copy1.fontStretch});
    line-height: var(${(props) => props.theme.responsive.typography.copy1.lineHeight});
  }

  &:hover {
    border-color: var(
      ${(props) =>
        props.disabled ? props.theme.colors.ui.divider : props.theme.colors.ui.tertiary}
    );
    color: var(
      ${(props) =>
        props.disabled
          ? props.theme.colors.interaction.primary.disabled
          : props.theme.colors.ui.tertiary}
    );
  }

  &:focus {
    border-color: var(${(props) => props.theme.colors.ui.divider});
  }
`;

export const Wrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: var(${(props) => props.theme.responsive.spacing.xxs});
`;
