import { Icon, Text } from '@audi/audi-ui-react';
import type { FC, MouseEvent } from 'react';
import React from 'react';
import styled from 'styled-components';

export interface FilterCategoryButtonProps {
  readonly onClickHandler: (event: MouseEvent) => void;
  readonly text: string;
  readonly isActive?: boolean;
}

export const FilterCategoryButton: FC<FilterCategoryButtonProps> = React.forwardRef<
  HTMLButtonElement,
  FilterCategoryButtonProps
>(({ onClickHandler, text, isActive }, ref) => (
  <StyledButton
    type="button"
    onClick={(event) => onClickHandler(event)}
    isActive={isActive}
    ref={ref}
    data-isactive={isActive}
    aria-label={`Filter category ${text}`}
    data-testid={`filterCategoryBtn-${text?.split(' ')[0]}`}
  >
    <Text variant="copy2">{text}</Text>
    <Icon name={isActive ? 'forward-active' : 'forward'} size="s" />
  </StyledButton>
));

const StyledButton = styled.button<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(${(props) => props.theme.responsive.spacing.m}) 0;
  border: none;
  background-color: transparent;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-align: left;
  cursor: pointer;
  appearance: none;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    padding: 0;

    & + & {
      margin-top: var(${(props) => props.theme.responsive.spacing.l});
    }
  }

  &:hover {
    p {
      color: var(${(props) => props.theme.colors.ui.primary});
    }

    svg {
      color: var(${(props) => props.theme.colors.ui.primary});
    }
  }

  p {
    color: var(
      ${(props) =>
        props.isActive ? props.theme.colors.ui.primary : props.theme.colors.ui.tertiary}
    );
    font-family: var(${(props) => props.theme.responsive.typography.copy2.fontFamily});
    font-size: var(${(props) => props.theme.responsive.typography.copy2.fontSize});
    font-stretch: var(${(props) => props.theme.responsive.typography.copy2.fontStretch});
    line-height: var(${(props) => props.theme.responsive.typography.copy2.lineHeight});

    @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
      font-family: var(${(props) => props.theme.responsive.typography.order4.fontFamily});
      font-size: var(${(props) => props.theme.responsive.typography.order4.fontSize});
      font-stretch: var(${(props) => props.theme.responsive.typography.order4.fontStretch});
      line-height: var(${(props) => props.theme.responsive.typography.order4.lineHeight});
    }
  }

  svg {
    color: var(${(props) => props.theme.colors.ui.tertiary});
  }
`;
