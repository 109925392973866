import { Text } from '@audi/audi-ui-react';
import React from 'react';
import type { FC } from 'react';

import styled from 'styled-components';
import { UeElement } from '@oneaudi/falcon-tools';
import { useContent } from '../context/ContentContext';

export const StyledDisclaimer = styled.div``;

const Disclaimer: FC = () => {
  const { disclaimer } = useContent();

  if (!disclaimer) {
    return null;
  }

  return (
    <StyledDisclaimer>
      <Text variant="copy2" data-testid="disclaimer">
        <UeElement type="text" property="disclaimer" label="Disclaimer">
          {disclaimer}
        </UeElement>
      </Text>
    </StyledDisclaimer>
  );
};

export default Disclaimer;
