import type { DisplayedVehicleType } from './filter';
import { getDefaultVehicleType, vehicleTypes } from './filter';

const URL_PARAM_BY_CARLINE_GROUP = 'bycarlinegroup';
const URL_PARAM_BY_TYPE = 'bytype';
const URL_PARAM_BY_BODY_TYPE = 'bybodytype';
const URL_PARAM_BY_VEHICLE_TYPE = 'byvehicletype';

export const getVehicleTypePreset = (filterActive: boolean): DisplayedVehicleType | undefined => {
  const vehicleTypePreset = getURLParameterValue(URL_PARAM_BY_VEHICLE_TYPE);

  if (vehicleTypePreset) {
    return vehicleTypePreset as DisplayedVehicleType;
  }

  if (filterActive) {
    return getDefaultVehicleType();
  }

  return vehicleTypes.length > 0 ? vehicleTypes[0] : undefined;
};

export const getCarlineGroupPreset = (preFilteredCarlineGroup: string) => {
  const carlineGroupPreset = getURLParameterValue(URL_PARAM_BY_CARLINE_GROUP);
  if (carlineGroupPreset) {
    return carlineGroupPreset.split(',');
  }
  if (preFilteredCarlineGroup) {
    return preFilteredCarlineGroup.split(',');
  }
  return [];
};

export const getBodyTypePreset = () => {
  let bodyTypePreset = getURLParameterValue(URL_PARAM_BY_BODY_TYPE);

  if (!bodyTypePreset) {
    // fallback to URL parameter used by former model finder
    bodyTypePreset = getURLParameterValue(URL_PARAM_BY_TYPE);
  }

  if (!bodyTypePreset) {
    return [];
  }

  return bodyTypePreset.split(',');
};

const getURLParameterValue = (urlParameter: string) => {
  if (typeof window === 'undefined') {
    return undefined;
  }

  const { search } = window.location;

  if (search) {
    const urlParams = new URLSearchParams(search);
    const urlParam = urlParams.get(urlParameter);

    return urlParam || undefined;
  }

  return undefined;
};
