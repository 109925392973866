/* eslint-disable no-restricted-syntax */
export const checkLinkTarget = (url: string) => {
  const tempLink = document.createElement('a');
  tempLink.href = url;

  if (tempLink.hostname === window.location.hostname) {
    return 'internal_link';
  }
  return 'exit_link';
};

export const completeTargetURL = (targetURL: string) => {
  const { host } = document.location;
  if (targetURL.startsWith('/')) {
    return `${host}${targetURL}`;
  }
  return targetURL;
};

export const getTrackingSearchParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const keys = ['carline', 'bybodytype', 'bycarlinegroup', 'byvehicletype', 'bytype'];
  const result: { id: string; values: string[] }[] = [];

  for (const key of keys) {
    const value = urlParams.get(key);
    if (value) {
      result.push({ id: key, values: [value] });
    }
  }
  return result;
};

export const getGroupIdfromCarlineId = (carlineId: string) => `ÈTRONG - ${carlineId}`;
