import type { BreakpointLabel } from '@audi/audi-ui-react';
import { BREAKPOINT_SXXL, BREAKPOINT_IPHONE, BREAKPOINT_SMALL_TABLET } from '../constants';

type BreakpointLabelExtended = BreakpointLabel | 'sxxl' | 'xxs';

type UrlParams = Record<string, string | number | boolean>;
type TSrcSet = { src: string; width?: string }[];
type TSizes = {
  min?: BreakpointLabelExtended;
  max?: BreakpointLabelExtended;
  width: string;
}[];

export const getSrcSet = (srcSet?: TSrcSet) => {
  if (!srcSet) return undefined;

  return srcSet
    .map((srcSetItem) => {
      let result = srcSetItem.src;

      if ('width' in srcSetItem) {
        result += ` ${srcSetItem.width}`;
      }

      return result.trim();
    })
    .join(', ');
};

export const getScreenSizeBreakpoint = (breakpoint: BreakpointLabelExtended) => {
  switch (breakpoint.toLowerCase()) {
    case 'xxs': {
      return 375;
    }
    case 'xs': {
      return BREAKPOINT_IPHONE;
    }
    case 's': {
      return BREAKPOINT_SMALL_TABLET;
    }
    case 'm': {
      return 768;
    }
    case 'l': {
      return 1024;
    }
    case 'xl': {
      return 1440;
    }
    case 'sxxl': {
      return BREAKPOINT_SXXL;
    }
    case 'xxl': {
      return 1920;
    }
    default: {
      // eslint-disable-next-line no-console
      console.warn('getScreenSizeBreakpoint: Something went wrong');
      return 0;
    }
  }
};

export const getSizes = (sizes?: TSizes) => {
  if (!sizes) return undefined;

  return [...sizes]
    .map(({ min, max, width }) => {
      if (min || max) {
        const minValue = min ? `${getScreenSizeBreakpoint(min)}px` : min;
        const maxValue = max ? `${getScreenSizeBreakpoint(max)}px` : max;
        const minQuery = min ? `(min-width: ${minValue})` : '';
        const maxQuery = max ? `(max-width: ${maxValue})` : '';
        const query = `${minQuery} ${min && max ? ' and ' : ' '}${maxQuery}`.trim();
        return `(${query}) ${width}`;
      }

      return width;
    })
    .join(', ');
};

export const getOptimizedImageSrc = (
  src: string | undefined,
  options: {
    width?: string | number;
    extension?: string;
  },
) => {
  if (!src || !src?.includes('https://')) return '';

  if (!options) return src;
  let optimizedSrc = src;

  const { extension, width } = options;

  if (extension) {
    optimizedSrc = optimizedSrc.replace(/(\.\w+)?($|\?)/, `.${extension}$2`);
  }

  if (width) {
    optimizedSrc = addParamsToUrl(optimizedSrc, { wid: width });
  }

  return optimizedSrc;
};

export const sanitizeUrl = (url: string) => {
  if (!url) return '';

  let sanitizedUrl = url;

  if (typeof window !== 'undefined') {
    if (sanitizedUrl.startsWith('//')) {
      sanitizedUrl = `${window.location.protocol}${sanitizedUrl}`;
    }
  }

  // Remove double forward slash (`/`)
  sanitizedUrl = sanitizedUrl.replace(/([^:]\/)\/+/g, '$1');

  return sanitizedUrl;
};

export const addParamsToUrl = (url: string, params: UrlParams) => {
  if (!url) return '';
  const urlObject = new URL(sanitizeUrl(url));

  Object.entries(params).forEach(([paramKey, paramValue]) => {
    urlObject.searchParams.set(paramKey, String(paramValue));
  });

  return urlObject.toString();
};
