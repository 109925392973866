import React from 'react';
import type { TrackingManager } from '../hooks/useTrackingManager';
import { useTrackingManager } from '../hooks/useTrackingManager'; // adjust the import path accordingly

interface WithTrackingProps {
  trackingManager: TrackingManager;
}

const withTracking =
  <P extends WithTrackingProps>(
    WrappedComponent: React.ComponentType<P>,
  ): React.FC<Omit<P, keyof WithTrackingProps>> =>
  (props: Omit<P, keyof WithTrackingProps>) => {
    const trackingManager = useTrackingManager();

    return <WrappedComponent {...(props as P)} trackingManager={trackingManager} />;
  };

export default withTracking;
