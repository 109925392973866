import type { ContentServiceV1 } from '@oneaudi/content-service';
import type { Content, FalconContent } from '../context/ContentContext';

export const normalizeContent = (contentService: ContentServiceV1): Content => {
  const cmsContent = contentService.getContent() as Content | FalconContent;
  if ('__type' in cmsContent && cmsContent.__type === 'aem-headless') {
    const normalizedContent: Content = {
      stockCarsLink: cmsContent.fields.stockCarsLink,
      preownedCarsLink: cmsContent.fields.preownedCarsLink,
      priceDisplayOption: {
        showBasePrice: cmsContent.fields.priceDisplayOption_showBasePrice,
        showVat: cmsContent.fields.priceDisplayOption_showVat ?? true,
      },
      caeDisplayOption: {
        showCae: cmsContent.fields.caeDisplayOption_showCae,
      },
      ctaDisplayOptions: {
        showConfigureCTA: cmsContent.fields.ctaDisplayOptions_showConfigureCTA,
        showNewAndUsedCarsCTA: cmsContent.fields.ctaDisplayOptions_showNewAndUsedCarsCTA,
        discoverLinkIsPrimaryCta: cmsContent.fields.ctaDisplayOptions_discoverLinkIsPrimaryCta,
      },
      priceFootnotes: cmsContent.fields.priceFootnotes,
      preFilteredCarlineGroup: cmsContent.fields.preFilteredCarlineGroup,
      disclaimer: cmsContent.fields.disclaimer,
    };
    return normalizedContent;
  }
  return cmsContent as Content;
};
