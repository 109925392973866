import React, { useEffect, useState, type FC } from 'react';
import { FilterTag, Wrapper } from './filterTag';
import type { Carline } from '../../utils/filter';
import { useUniversalState } from '../../context/UniversalStateContext';
import type { CarlineGroup } from '../../../@types/mapped';
import type { TrackingManager } from '../../hooks/useTrackingManager';
import withTracking from '../../hoc/withTracking';

interface FilterCarlineTagsProps {
  readonly carlineGroups: CarlineGroup[];
  readonly filteredCarlines: Carline[] | undefined;
  readonly selectedCarlineGroups: string[];
  readonly createOnClickHandler: (id: string) => () => void;
  readonly cutItemsAfter?: number;
  readonly trackingManager: TrackingManager;
}

const FilterCarlineTags: FC<FilterCarlineTagsProps> = ({
  carlineGroups,
  filteredCarlines,
  selectedCarlineGroups,
  createOnClickHandler,
  cutItemsAfter,
  trackingManager,
}) => {
  if (!filteredCarlines) {
    return undefined;
  }
  const { translations } = useUniversalState();

  const [isShortened, setIsShortened] = useState<boolean>(
    cutItemsAfter ? carlineGroups.length > cutItemsAfter : false,
  );

  useEffect(() => {
    setIsShortened(cutItemsAfter ? carlineGroups.length > cutItemsAfter : false);
  }, [carlineGroups.length, cutItemsAfter]);

  let tags = carlineGroups?.map(({ identifier: { id } }) => {
    const isSelected = selectedCarlineGroups.includes(id);
    const isDisabled =
      filteredCarlines &&
      filteredCarlines.length > 0 &&
      !filteredCarlines?.some((carline) => carline.group === id) &&
      !isSelected;
    return (
      <FilterTag
        key={id}
        text={translations[`carlineGroup.${id}`]}
        onClickHandler={createOnClickHandler(id)}
        selected={isSelected}
        disabled={isDisabled}
        dataTestId={`filterModelle-${id?.replace(/\s+/g, '')}`}
      />
    );
  });
  if (isShortened) {
    tags = tags.slice(0, cutItemsAfter);
  }
  return (
    <Wrapper>
      {React.Children.map(tags, (tag) => (
        <div>{tag}</div>
      ))}
      {isShortened && (
        <div>
          <FilterTag
            key="expand"
            text=""
            onClickHandler={() => {
              setIsShortened(false);
              if (trackingManager) {
                trackingManager.trackShowMoreFilterClick('models');
              }
            }}
            icon="more"
            dataTestId="expandModelleFilter"
          />
        </div>
      )}
    </Wrapper>
  );
};
export const TrackedFilterCarlineTags = withTracking(FilterCarlineTags);
